:root {
  --color-cream: #FAF3EA;
  --color-cream-light: #FAF5EE;

  --color-gray-dark: #2D2D2D;
  --color-gray-medium: #5F5F5F;
  --color-gray-light: #949594;

  --color-shadow: rgba(197, 197, 197, .5);
  --box-shadow: 0 0 3px 1px var(--color-shadow);

  --grid-width: 960px;
  --grid-width-small: 720px;
}
