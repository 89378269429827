.copy-to-clipboard {
  &__button {
    color: var(--color-gray-dark);
    background-color: var(--color-cream);
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    border: none;

    margin-left: 5px;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }

    &:focus {
      outline: none;
    }
  }
}

