.header {
  background-color: var(--color-gray-dark);
  color: var(--color-cream);

  &__flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    color: var(--color-cream);
    text-decoration: none;

    &__title {
      font-size: 36px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 15px;
      margin-top: 20px;

      @media only screen and (max-device-width: 375px) {
        font-size: 24px;
      }

      span {
        font-weight: 700;
      }
    }

    &__dash {
      height: 2px;
      width: 100px;
      border-bottom: 2px solid var(--color-cream);
      margin-bottom: 20px;
    }
  }

  nav a {
    color: var(--color-cream);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 20px;

    &:hover {
      border-bottom: 2px solid var(--color-cream);
    }
  }
}
