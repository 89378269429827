.hero-container {
  background-color: var(--color-gray-dark);
  color: var(--color-cream);
  padding: 90px 0;

  @media only screen and (max-device-width: 736px) {
    padding: 40px 0;
  }

  @media only screen and (max-device-width: 375px) {
    padding: 30px 0;
  }
}

.hero {
  a {
    color: var(--color-cream);
  }

  &__title {
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }

  &__dash {
    height: 2px;
    width: 100px;
    border-bottom: 2px solid var(--color-cream);
    margin-bottom: 30px;
  }

  &__text {
    max-width: 380px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

main {
  section > * {
    max-width: var(--grid-width-small);
  }

  & {
    padding-top: 60px;
  }

  @media only screen and (max-device-width: 736px) {
    padding-top: 40px;
  }

  @media only screen and (max-device-width: 375px) {
    padding-top: 30px;
  }
}

.section {
  margin-bottom: 60px;

  &__heading {
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 5px;
    color: var(--color-gray-light);
  }

  &__item__heading {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
  }
}

.post-list {
  .section__heading {
    margin-bottom: 20px
  }

  &__post {
    text-decoration: none;
    display: block;
    padding: 20px; // total is 20 + 20 to equal other section headings
    margin: 0 -20px 20px -20px;
    border-radius: 2px;
    transition: background-color .2s, box-shadow .2s;

    header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      h3 {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.3;
        flex: 0 1 450px;
      }

      time {
        margin-bottom: 12px;
      }
    }

    p {
      margin-bottom: 0;
    }

    &:hover {
      background-color: var(--color-cream-light);
      box-shadow: var(--box-shadow);
    }
  }
}
