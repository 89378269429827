html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  font-family: 'Open Sans', sans-serif;

  background-color: var(--color-cream);
  color: var(--color-gray-dark);
}

main {
  flex: 1 0 auto;
  background-color: var(--color-cream);
}

a {
  color: var(--color-gray-dark);
}

p {
  line-height: 24px;
  margin-bottom: 20px;
}

.grid {
  max-width: var(--grid-width);
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-device-width: 375px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

h1 {
  font-size: 24px;
  font-weight: 700;
}

.estimate-in-words {
  font-size: 14px;
  font-style: italic;
  color: var(--color-gray-medium);
  margin: 0;
}
