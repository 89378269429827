.post {
  &__content {
    > * {
      max-width: var(--grid-width-small);
    }
  }

  &__header {
    margin-bottom: 25px;

    h1 {
      margin: 20px 0 5px 0;
    }
  }
}
