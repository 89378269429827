footer {
  margin-top: auto;
  padding-top: 50px;
}

.footer {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-light);
  padding: 40px;

  @media only screen and (max-device-width: 375px) {
    padding: 30px;
  }

  p {
    margin-bottom: 0;
  }

  &__name {
    text-transform: uppercase;

    span {
      font-weight: 700;
    }
  }
}
